import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  var { transaction, unitType, intl, bookingServiceType } = props;
  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(item => {
    if (item.code === 'line-item/day') {
      unitType = 'line-item/day';
      return true;
    }
    return item.code === unitType && !item.reversal;
  });
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  let translationKey = 'BookingBreakdown.baseUnitQuantity';

  if (isNightly) {
    translationKey = 'BookingBreakdown.baseUnitNight';
  }
  if (isDaily) {
    translationKey = 'BookingBreakdown.baseUnitDay';
  }
  if (bookingServiceType === 'walking') {
    translationKey = 'BookingBreakdown.baseUnitWalks';
  }

  if (bookingServiceType === 'feeding') {
    translationKey = 'BookingBreakdown.baseUnitFeeds';
  }

  console.log(translationKey)
  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
